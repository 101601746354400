<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item><a href="/">首页</a></el-breadcrumb-item>
            <el-breadcrumb-item>教师列表</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">教师列表</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 列表 -->
    <div class="wrap">
      <el-row class="operation" :gutter="20">
        <el-col :span="4">
          <el-input v-model="name" placeholder="搜索教师" @input="change"></el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="tebleAdd">添加教师</el-button>
        </el-col>
      </el-row>
      <el-table :data="List" border height="520">
        <el-table-column label="头像">
          <template slot-scope="scope">
            <el-image style="width: 100px;height: 100px;" :src="scope.row.teacherHead" fit="fill"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="teacherName" label="讲师姓名">
        </el-table-column>
        <el-table-column prop="teacherPhone" label="联系电话">
        </el-table-column>
        <el-table-column prop="createTime" label="添加时间">
        </el-table-column>
        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <!-- <el-button type="text" @click="review(scope.row.teacherId)">查看</el-button> -->
            <el-button type="text" style="color:#E6A23C" @click="centerDialogVisible = true">邀请</el-button>
            <el-popconfirm title="确定删除此条数据吗？" @confirm='deletes(scope.row.teacherId)'>
              <el-button type="text" slot="reference" style="margin-left:10px">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNo" background :page-size="pageSize" layout="total, prev, pager, next" :total="total" style="text-align: right;">
      </el-pagination>
    </div>
    <el-dialog title="提示" :visible.sync="centerDialogVisible" width="30%" center>
      <div class="popup" style="text-align:center">
        <vue-qr :text="imgUrl" :size="250" :logoSrc="logo" :logoScale="0.2"></vue-qr>
        <div>截图或下载此二维码发给讲师</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="downloadQrcode">下载二维码</el-button>
      </span>
    </el-dialog>
  </div>
</template>



<script>
import VueQr from 'vue-qr'
export default {
  components: {
    VueQr,
  },
  data() {
    return {
      //搜索
      name: '',
      // 分页
      pageNo: 1,
      //数量
      pageSize: 50,
      // 总条数
      total: 0,
      // 列表
      List: [],
      //邀请二维码
      centerDialogVisible: false,
      imgUrl: 'https://ylapp.nongliankeji.cn',
      logo: require('@/assets/logo.png'),
    }
  },
  created() {
    this.getList()
  },
  methods: {
    // 列表
    async getList() {
      const { data: res } = await this.$http.get('/admin/teacher/getPageAllByOrganizationId', {
        params: {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          teacherName: this.name,
          organizationId: window.sessionStorage.getItem("organizationId")
        }
      })
      this.List = res.data.rows
      this.total = res.data.totalRows
    },
    //删除
    deletes(id) {
      this.remove(id)
    },
    async remove(id) {
      const { data: res } = await this.$http.post('/admin/teacher/delete', { teacherId: id })
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      this.getList()
    },
    //编辑
    review(id) {
      this.$router.push({
        path: '/',
        query: {
          id: id
        }
      });
    },
    //添加
    tebleAdd() {
      this.$router.push({
        path: '/u_teacher_add',
      });
    },
    //搜索
    change(e) {
      this.name = e
      this.getList()
    },
    // 分页-数量
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    // 分页-页码
    handleCurrentChange(val) {
      this.pageNo = val
      this.getList()
    },
    downloadQrcode() {
      let myImg = document.querySelector(".popup img");
      let url = myImg.src;
      let a = document.createElement('a');
      a.href = url;
      a.download = '邀请二维码'; //下载图名称
      a.click();
      this.$message({
        message: "下载成功",
        type: 'success'
      })
    }

  }
}
</script>
<style scoped lang="scss"></style>