<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/release_problem_list' }">问题列表</el-breadcrumb-item>
            <el-breadcrumb-item>问题详情</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">问题详情</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 详情 -->
    <div class="wrap">
      <el-row :gutter="20" class="min_height_card">
        <!-- 个人认证 -->
        <el-col :span="24">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>问题详情</span>
              <i class="el-icon-edit floatCaid" style="margin-left:20px" @click="toEditor(checkSelfs.questionId)"></i>
              <div class="floatCaid floatCaids" v-if="checkSelfs.status==0">
                <el-button type="text" @click="approveSelfBtn(checkSelfs.questionId)">通过</el-button>
                <el-button type="text" @click="open">驳回</el-button>
              </div>
              <el-link class="floatCaid" type="success" v-else-if="checkSelfs.status==1">已通过</el-link>
              <el-link class="floatCaid" type="danger" v-else-if="checkSelfs.status==2">已驳回</el-link>
            </div>
            <div>
              <ul class="msgList">
                <li>
                  <span class="title_text">问题描述：</span>
                  <p v-if="checkSelfs.questionContent">{{checkSelfs.questionContent}}</p>
                </li>
                <li>
                  <span class="title_text">问题悬赏：</span>
                  <p v-if="checkSelfs.questionFee">&yen;&nbsp;{{checkSelfs.questionFee}}</p>
                </li>
                <li>
                  <span class="title_text">问题介绍：</span>
                  <p v-if="checkSelfsImg!=''">
                    <span v-for="(item,i) in checkSelfsImg" :key="i">
                      <el-image :src="item" :preview-src-list="checkSelfsImg" class="uploadImg"></el-image>
                    </span>
                  </p>
                  <p v-else>暂无图片</p>
                </li>
                <li v-if="checkSelfs.reason">
                  <span class="title_text">驳回原因：</span>
                  <p>
                    <el-link type="danger">{{checkSelfs.reason}}</el-link>
                  </p>
                </li>
              </ul>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-table :data="List" border height="520" style="margin-top:20px">
        <el-table-column prop="userId" label="回答人">
        </el-table-column>
        <el-table-column prop="answerContent" label="回答内容">
        </el-table-column>
        <el-table-column prop="createTime" label="回答时间">
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <el-link :underline="false" type="info" v-if="scope.row.isUse==0">未采纳</el-link>
            <el-link :underline="false" type="success" v-else-if="scope.row.isUse==1">已采纳</el-link>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <el-popconfirm title="确定删除此条数据吗？" @confirm='deletes(scope.row.answerId)'>
              <el-button type="text" slot="reference" style="margin-left:10px">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>





<script>
export default {
  data() {
    return {
      id: '',
      checkSelfs: '',
      checkSelfsImg: [],
      checkSelfsreason: '',
      // 列表
      List: [],
    }
  },
  created() {
    this.id = this.$route.query.id
    this.checkSelf()
    this.getAllByQuestionIdPage()
  },
  methods: {
    //点击跳转到修改信息页面
    toEditor(id) {
      this.$router.push({
        path: '/release_problem_editor',
        query: {
          id: id
        }
      });
    },
    //查看
    async checkSelf() {
      const { data: res } = await this.$http.get("/admin/question/detail", {
        params: {
          questionId: this.id
        }
      })
      this.checkSelfs = res.data
      this.checkSelfsImg = res.data.pics
    },
    //通过
    approveSelfBtn(id) {
      this.approveSelf(id)
    },
    async approveSelf(id) {
      const { data: res } = await this.$http.get("/admin/question/approveQuestion", {
        params: {
          questionId: id
        }
      })
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      this.checkSelf()
    },
    //驳回
    open() {
      this.$prompt('请输入驳回原因', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[\u4e00-\u9fa5_a-zA-Z0-9\s\·\~\！\@\#\￥\%\……\&\*\（\）\——\-\+\=\【\】\{\}\、\|\；\‘\’\：\“\”\《\》\？\，\。\、\`\~\!\#\$\%\^\&\*\(\)\_\[\]{\}\\\|\;\'\'\:\"\"\,\.\/\<\>\?]+$/,
        inputErrorMessage: '输入有误',
        closeOnClickModal: false,
      }).then(({ value }) => {
        this.checkSelfsreason = value
        this.rejectSelf()
      })
    },
    async rejectSelf() {
      const { data: res } = await this.$http.post("/admin/question/rejectQuestion?questionId=" + this.id + '&reason=' + this.checkSelfsreason)
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      this.checkSelf()
    },
    //回答列表
    async getAllByQuestionIdPage() {
      const { data: res } = await this.$http.get('/admin/answer/getAllByQuestionIdPage', {
        params: {
          questionId: this.id,
        }
      })
      this.List = res.data
    },
    //删除
    deletes(id) {
      this.remove(id)
    },
    async remove(id) {
      const { data: res } = await this.$http.post('/admin/answer/delete', { answerId: id })
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      this.getAllByQuestionIdPage()
    },
  }
}
</script>





<style scoped lang="less">
.msgList {
  font-size: 14px;
  color: #000;
  list-style-type: none;
  li {
    position: relative;
    padding-left: 100px;
    margin-top: 20px;
    line-height: 20px;
  }
  .title_text {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 100px;
    text-align: right;
    font-weight: normal;
    color: #a4a4a4;
    padding-right: 10px;
  }
  p {
    display: inline-block;
    width: 100%;
    text-align: left;
  }
}
.title_title {
  font-size: 18px;
  color: #000;
  font-weight: bold;
  margin: 20px 0;
  text-align: center;
}
.uploadImg {
  width: 65px;
  height: 65px;
  margin-right: 10px;
}
.min_height_card {
  .box-card {
    margin-top: 20px;
    min-height: 280px;
  }
}
.floatCaid {
  float: right;
  padding: 3px 0;
}
.floatCaids {
  margin-top: -12px;
}
</style>