<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/release_task_list' }">任务列表</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/release_task_details?id='+id }">任务管理</el-breadcrumb-item>
            <el-breadcrumb-item>任务编辑</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">编辑任务信息</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 详情 -->
    <div class="wrap">
      <div class="formWidth">
        <el-form :model="form" :rules="rules" ref="form" :label-position="labelPosition" :hide-required-asterisk="true">
          <div class="grid-content">
            <el-form-item label="任务标题" :label-width="formLabelWidth" prop="taskTitle">
              <el-input v-model="form.taskTitle" autocomplete="off" placeholder="请输入任务标题"></el-input>
            </el-form-item>
            <el-form-item label="任务内容" :label-width="formLabelWidth" prop="taskContent">
              <el-input v-model="form.taskContent" autocomplete="off" placeholder="请输入任务内容"></el-input>
            </el-form-item>
            <el-form-item label="任务价格" :label-width="formLabelWidth" prop="taskFee">
              <el-input v-model="form.taskFee" autocomplete="off" placeholder="请输入任务价格"></el-input>
            </el-form-item>
            <el-form-item label="任务单位" :label-width="formLabelWidth" prop="taskUnit">
              <el-input v-model="form.taskUnit" autocomplete="off" placeholder="请输入任务单位（亩/次/天/人/吨/公斤）"></el-input>
            </el-form-item>
            <el-form-item label="任务开始时间" :label-width="formLabelWidth" prop="startTime">
              <el-date-picker v-model="form.startTime" type="datetime" placeholder="请选择任务开始时间" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-form-item>
            <el-form-item label="任务结束时间" :label-width="formLabelWidth" prop="endTime">
              <el-date-picker v-model="form.endTime" type="datetime" placeholder="请选择任务结束时间" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <!-- 提交按钮 -->
      <div class="formButton">
        <el-button type="primary" @click="submitForm('form')">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 当前数据id
      id: '',
      // 表单信息
      form: {},
      //图片
      fileList: [],
      // 表单信息验证项
      rules: {
        taskTitle: [
          { required: true, message: '请输入任务标题', trigger: 'blur' },
        ],
        taskContent: [
          { required: true, message: '请输入任务内容', trigger: 'blur' },
        ],
        taskFee: [
          { required: true, message: '请输入任务价格', trigger: 'blur' },
        ],
        startTime: [
          { required: true, message: '请选择任务开始时间', trigger: 'blur' },
        ],
        endTime: [
          { required: true, message: '请选择任务结束时间', trigger: 'blur' },
        ],
        taskUnit: [
          { required: true, message: '请输入任务单位', trigger: 'blur' },
        ],
      },
      // 左侧标题宽度
      formLabelWidth: '120px',
      //左侧标题对齐
      labelPosition: 'left',
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getDetails()
  },
  methods: {
    //获取
    async getDetails() {
      const { data: res } = await this.$http.get("/admin/task/detail", {
        params: {
          taskId: this.id
        }
      })
      this.form = res.data
    },
    //提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.putChange()
        }
      });
    },
    async putChange() {
      const { data: res } = await this.$http.post("/admin/task/edit", this.form)
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      setTimeout(() => {
        this.$router.go(-1)
      }, 1000);
    },
  }
}
</script>
<style scoped lang="less"></style>