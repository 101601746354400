<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/release_demand_list' }">采购列表</el-breadcrumb-item>
            <el-breadcrumb-item>添加采购</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">添加采购信息</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 详情 -->
    <div class="wrap">
      <div class="formWidth">
        <el-form :model="form" :rules="rules" ref="form" :label-position="labelPosition" :hide-required-asterisk="true">
          <div class="grid-content">
            <el-form-item label="产品名称" :label-width="formLabelWidth" prop="needName">
              <el-input v-model="form.needName" autocomplete="off" placeholder="请输入产品名称"></el-input>
            </el-form-item>
            <el-form-item label="采购量" :label-width="formLabelWidth" prop="needNum">
              <el-input v-model="form.needNum" autocomplete="off" placeholder="请输入采购量"></el-input>
            </el-form-item>
            <el-form-item label="采购单位" :label-width="formLabelWidth" prop="needUnit">
              <el-select v-model="form.needUnit" placeholder="请选择" @change="change">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="其他要求" :label-width="formLabelWidth" prop="needDesc">
              <el-input v-model="form.needDesc" autocomplete="off" placeholder="请输入其他要求"></el-input>
            </el-form-item>
            <el-form-item label="采购开始时间" :label-width="formLabelWidth" prop="startTime">
              <el-date-picker v-model="form.startTime" type="datetime" placeholder="请选择采购开始时间" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-form-item>
            <el-form-item label="采购结束时间" :label-width="formLabelWidth" prop="endTime">
              <el-date-picker v-model="form.endTime" type="datetime" placeholder="请选择采购结束时间" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <!-- 提交按钮 -->
      <div class="formButton">
        <el-button type="primary" @click="submitForm('form')">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 当前数据id
      id: '',
      // 表单信息
      form: {
        needName: '',
        needNum: '',
        needDesc: '',
        startTime: '',
        endTime: '',
        status: '1',
        needUnit: '',
      },
      // 表单信息验证项
      rules: {
        needName: [
          { required: true, message: '请输入产品名称', trigger: 'blur' },
        ],
        needNum: [
          { required: true, message: '请输入采购量', trigger: 'blur' },
        ],
        needDesc: [
          { required: true, message: '请输入其他要求', trigger: 'blur' },
        ],
        startTime: [
          { required: true, message: '请选择采购开始时间', trigger: 'blur' },
        ],
        endTime: [
          { required: true, message: '请选择采购结束时间', trigger: 'blur' },
        ],
        needUnit: [
          { required: true, message: '请输入采购单位', trigger: 'blur' },
        ],
      },
      //单位选择
      options: [{
        value: '亩',
        label: '亩'
      }, {
        value: '次',
        label: '次'
      }, {
        value: '天',
        label: '天'
      }, {
        value: '人',
        label: '人'
      },
      {
        value: '吨',
        label: '吨'
      }, {
        value: '斤',
        label: '斤'
      }, {
        value: '公斤',
        label: '公斤'
      }],
      // 左侧标题宽度
      formLabelWidth: '120px',
      //左侧标题对齐
      labelPosition: 'left',
    }
  },
  created() {
    this.id = this.$route.query.id
  },
  methods: {
    //选择单位
    change(e) {
      this.form.needUnit = e
    },
    //提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.putChange()
        }
      });
    },
    async putChange() {
      const { data: res } = await this.$http.post("/admin/need/add", this.form)
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      setTimeout(() => {
        this.$router.go(-1)
      }, 1000);
    },
  }
}
</script>
<style scoped lang="less"></style>