<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/release_task_list' }">任务列表</el-breadcrumb-item>
            <el-breadcrumb-item>任务详情</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">任务详情</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 详情 -->
    <div class="wrap">
      <el-row :gutter="20" class="min_height_card">
        <!-- 个人认证 -->
        <el-col :span="24">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>任务详情</span>
              <i class="el-icon-edit floatCaid" style="margin-left:20px" @click="toEditor(checkSelfs.taskId)"></i>
              <div class="floatCaid floatCaids" v-if="checkSelfs.status==0">
                <el-button type="text" @click="approveSelfBtn(checkSelfs.taskId)">通过</el-button>
                <el-button type="text" @click="open">驳回</el-button>
              </div>
              <el-link class="floatCaid" type="success" v-else-if="checkSelfs.status==1">已通过</el-link>
              <el-link class="floatCaid" type="danger" v-else-if="checkSelfs.status==2">已驳回</el-link>
            </div>
            <div>
              <ul class="msgList">
                <li>
                  <span class="title_text">任务标题：</span>
                  <p v-if="checkSelfs.taskTitle">{{checkSelfs.taskTitle}}</p>
                </li>
                <li>
                  <span class="title_text">任务内容：</span>
                  <p v-if="checkSelfs.taskContent">{{checkSelfs.taskContent}}</p>
                </li>
                <li>
                  <span class="title_text">任务价格：</span>
                  <p v-if="checkSelfs.taskFee">&yen;&nbsp;{{checkSelfs.taskFee}}</p>
                </li>
                <li>
                  <span class="title_text">任务时间：</span>
                  <p v-if="checkSelfs.startTime">{{checkSelfs.startTime}}至{{checkSelfs.endTime}}</p>
                </li>
                <li v-if="checkSelfs.reason">
                  <span class="title_text">驳回原因：</span>
                  <p>
                    <el-link type="danger">{{checkSelfs.reason}}</el-link>
                  </p>
                </li>
                <li><span class="title_text">标签：</span>
                  <p>
                    <el-select v-model="userLabel" filterable multiple placeholder="请选择任务标签" @remove-tag="removeTag" @change="changeNumid">
                      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                    <el-button type="primary" style="margin-left:10px" @click="changeNumidUp">保存</el-button>
                  </p>
                </li>
              </ul>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>





<script>
export default {
  data() {
    return {
      id: '',
      form: '',
      checkSelfs: '',
      checkSelfsreason: '',
      // 打标签
      labelValue: 'task',
      options: [],
      userLabel: [],
      arrList: [],
    }
  },
  created() {
    this.id = this.$route.query.id
    this.checkSelf()
    //获取标签
    this.getByTypePage()
    //获取已经打的标签
    this.getUseLabelList()
  },
  methods: {
    //获取全部标签
    async getByTypePage() {
      const { data: res } = await this.$http.get('/admin/label/getListByType', {
        params: {
          labelType: this.labelValue,
        }
      })
      var data = [];
      res.data.map((value, index, arry) => {
        data.push({
          value: value.labelId,
          label: value.labelName,
        });
      });
      this.options = data
    },
    // 查看当前已经打的标签
    async getUseLabelList() {
      const { data: res } = await this.$http.post('/admin/labelUse/getUseLabelList?labelType=' + this.labelValue + '&useId=' + this.id)
      var data1 = [];
      res.data.map((value, index, arry) => {
        data1.push(value.labelId);
      });
      this.userLabel = data1
    },
    //打标签
    changeNumid(i) {
      this.arrList = i
    },
    changeNumidUp() {
      this.makeLabel()
    },
    async makeLabel() {
      const { data: res } = await this.$http.post("/admin/labelUse/makeLabelBatch?labelType=" + this.labelValue + '&useId=' + this.id + '&labelIds=' + this.arrList)
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
    },
    //删除标签
    removeTag(i) {
      this.unMakeLabel(i)
    },
    async unMakeLabel(i) {
      const { data: res } = await this.$http.post("/admin/labelUse/unMakeLabel?labelType=" + this.labelValue + '&useId=' + this.id + '&labelId=' + i)
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
    },
    //点击跳转到修改信息页面
    toEditor(id) {
      this.$router.push({
        path: '/release_task_editor',
        query: {
          id: id
        }
      });
    },
    //查看
    async checkSelf() {
      const { data: res } = await this.$http.get("/admin/task/detail", {
        params: {
          taskId: this.id
        }
      })
      this.checkSelfs = res.data
    },
    //通过
    approveSelfBtn(id) {
      this.approveSelf(id)
    },
    async approveSelf(id) {
      const { data: res } = await this.$http.get("/admin/task/approveTask", {
        params: {
          taskId: id
        }
      })
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      this.checkSelf()
    },
    //驳回
    open() {
      this.$prompt('请输入驳回原因', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[\u4e00-\u9fa5_a-zA-Z0-9\s\·\~\！\@\#\￥\%\……\&\*\（\）\——\-\+\=\【\】\{\}\、\|\；\‘\’\：\“\”\《\》\？\，\。\、\`\~\!\#\$\%\^\&\*\(\)\_\[\]{\}\\\|\;\'\'\:\"\"\,\.\/\<\>\?]+$/,
        inputErrorMessage: '输入有误',
        closeOnClickModal: false,
      }).then(({ value }) => {
        this.checkSelfsreason = value
        this.rejectSelf()
      })
    },
    async rejectSelf() {
      const { data: res } = await this.$http.post("/admin/task/rejectTask?taskId=" + this.id + '&reason=' + this.checkSelfsreason)
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      this.checkSelf()
    },
  }
}
</script>





<style scoped lang="less">
.msgList {
  font-size: 14px;
  color: #000;
  list-style-type: none;
  li {
    position: relative;
    padding-left: 100px;
    margin-top: 20px;
    line-height: 20px;
  }
  .title_text {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 100px;
    text-align: right;
    font-weight: normal;
    color: #a4a4a4;
    padding-right: 10px;
  }
  p {
    display: inline-block;
    width: 100%;
    text-align: left;
  }
}
.title_title {
  font-size: 18px;
  color: #000;
  font-weight: bold;
  margin: 20px 0;
  text-align: center;
}
.uploadImg {
  width: 65px;
  height: 65px;
  margin-right: 10px;
}
.floatCaid {
  float: right;
  padding: 3px 0;
}
.floatCaids {
  margin-top: -12px;
}
.el-select {
  width: 500px !important;
}
</style>