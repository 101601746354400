<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/sysUser_list' }">系统用户列表</el-breadcrumb-item>
            <el-breadcrumb-item>修改机构信息</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">修改机构信息</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 详情 -->
    <div class="wrap">
      <div class="formWidth">
        <el-form :model="form" :rules="rules" ref="form" :label-position="labelPosition" :hide-required-asterisk="true">
          <div class="grid-content">
            <el-form-item label="机构名称" :label-width="formLabelWidth" prop="organizationName">
              <el-input v-model="form.organizationName" autocomplete="off" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="负责人" :label-width="formLabelWidth" prop="organizationOwner">
              <el-input v-model="form.organizationOwner" autocomplete="off" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="用户地区" :label-width="formLabelWidth" prop="organizationAddress">
              <el-cascader size="large" :options="options" v-model="form.organizationAddress" @change="handleChange" :placeholder="form.organizationAddress"></el-cascader>
            </el-form-item>
            <el-form-item label="机构电话" :label-width="formLabelWidth" prop="organizationPhone">
              <el-input v-model="form.organizationPhone" autocomplete="off" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="机构简介" :label-width="formLabelWidth" prop="organizationDesc">
              <el-input v-model="form.organizationDesc" autocomplete="off" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="机构证书" :label-width="formLabelWidth" prop="userCompany">
              <div>
                <el-upload :action="action" list-type="picture-card" :on-success="success" :limit="3" :file-list="fileList" :on-remove="remove">
                  <el-button size="small" type="primary">添加图片</el-button>
                </el-upload>
              </div>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <!-- 提交按钮 -->
      <div class="formButton">
        <el-button type="primary" @click="submitForm('form')">提交</el-button>
      </div>
    </div>
  </div>
</template>



<script>
import { regionData, CodeToText } from "element-china-area-data"
export default {
  data() {
    return {
      id: '',
      // 表单信息
      form: {
        organizationName: '',
        organizationOwner: '',
        organizationAddress: '',
        organizationPhone: '',
        organizationDesc: '',
        sysUserId: '',
      },
      // 表单信息验证项
      rules: {
        organizationName: [
          { required: true, message: '请输入机构名称', trigger: 'blur' },
        ],
        organizationOwner: [
          { required: true, message: "请输入负责人", trigger: "blur" },
        ],
        organizationAddress: [
          { required: true, message: '请输入机构地址', trigger: 'change' }
        ],
        organizationPhone: [
          { required: true, message: '请输入机构电话', trigger: 'change' }
        ],
        organizationDesc: [
          { required: true, message: '请输入机构简介', trigger: 'change' }
        ],
      },
      // 左侧标题宽度
      formLabelWidth: '120px',
      //左侧标题对齐
      labelPosition: 'left',
      // 所在地区
      options: regionData,
      //图片
      fileList: [],
    }
  },
  created() {
    this.id = this.$route.query.id
    this.form.sysUserId = this.$route.query.id
    this.getOrganizationBySysUserId()
    this.action = this.$http.defaults.baseURL + 'file/upLoadImage'
  },
  methods: {
    //先获取
    async getOrganizationBySysUserId() {
      const { data: res } = await this.$http.get("/admin/organization/getOrganizationBySysUserId?sysUserId=" + this.id)
      if (res.code != 200) return this.$message.error(res.message);
      if (res.data) {
        this.form = res.data
        if (res.data.pics != '') {
          let img = res.data.pics;
          let data = [];
          img.map((value, index, arry) => {
            data.push({ url: value });
          });
          this.fileList = data
        } else {
          this.fileList = []
        }
      }
    },
    //选择区域
    handleChange() {
      var loc = "";
      for (let i = 0; i < this.form.organizationAddress.length; i++) {
        loc += CodeToText[this.form.organizationAddress[i]];
      }
      this.form.organizationAddress = loc
    },
    //添加
    submitForm(formName) {
      let dataImg = []
      this.fileList.map((value, index, arry) => {
        dataImg.push(value.url);
      });
      this.form.pics = dataImg
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addForm()
        }
      });
    },
    async addForm() {
      const { data: res } = await this.$http.post("/admin/organization/addOrEdit", this.form)
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      setTimeout(() => {
        this.$router.go(-1)
      }, 1000);
    },
    //图片上传成功后
    success(response, file, fileList) {
      this.fileList.push({ url: response.data })
    },
    //移除图片
    remove(file, fileList) {
      this.fileList = []
      fileList.forEach(file => {
        this.fileList.push({ url: file.url });
      });
    },
  }
}
</script>



<style scoped lang="less"></style>