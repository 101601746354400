<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/release_task_list' }">任务列表</el-breadcrumb-item>
            <el-breadcrumb-item>任务推送</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">推送任务信息</div>
        </el-card>
      </el-col>
    </el-row>
    <div class="centerBox warps">
      <el-row :gutter="20">
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <el-form :model="form" :rules="rules" ref="form" :label-position="labelPosition" :hide-required-asterisk="true">
              <el-row :gutter="20">
                <el-col :span="24">
                  <div class="grid-content">
                    <el-form-item label="模板标题" :label-width="formLabelWidth">
                      <el-tag>下单成功通知</el-tag>
                    </el-form-item>
                    <el-form-item label="first" :label-width="formLabelWidth" prop="first">
                      <el-row>
                        <el-col :span="22">
                          <el-input v-model="form.first" autocomplete="off" placeholder="first"></el-input>
                        </el-col>
                      </el-row>
                    </el-form-item>
                    <el-form-item label="keyword1" :label-width="formLabelWidth" prop="keyword1">
                      <el-row>
                        <el-col :span="20">
                          <el-input v-model="form.keyword1" autocomplete="off" placeholder="keyword1"></el-input>
                        </el-col>
                        <el-col :span="4">
                          <el-color-picker v-model="form.color1" :predefine="predefineColors"></el-color-picker>
                        </el-col>
                      </el-row>
                    </el-form-item>
                    <el-form-item label="keyword2" :label-width="formLabelWidth" prop="keyword2">
                      <el-row>
                        <el-col :span="20">
                          <el-input v-model="form.keyword2" autocomplete="off" placeholder="keyword2"></el-input>
                        </el-col>
                        <el-col :span="4">
                          <el-color-picker v-model="form.color2" :predefine="predefineColors"></el-color-picker>
                        </el-col>
                      </el-row>
                    </el-form-item>
                    <el-form-item label="remark" :label-width="formLabelWidth" prop="remark">
                      <el-row>
                        <el-col :span="20">
                          <el-input v-model="form.remark" autocomplete="off" placeholder="remark"></el-input>
                        </el-col>
                        <el-col :span="4">
                          <el-color-picker v-model="form.remarkColor" :predefine="predefineColors"></el-color-picker>
                        </el-col>
                      </el-row>
                    </el-form-item>
                    <el-form-item label="url" :label-width="formLabelWidth" prop="url">
                      <el-row>
                        <el-col :span="22">
                          <el-input v-model="form.url" autocomplete="off" placeholder="url"></el-input>
                        </el-col>
                      </el-row>
                    </el-form-item>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" border height="500" style="width: 100%" @selection-change="handleSelectionChange">
              <el-table-column type="selection" width="55">
              </el-table-column>
              <el-table-column label="头像" width="120">
                <template slot-scope="scope">
                  <el-avatar :src="scope.row.userHead" size="small"></el-avatar>
                </template>
              </el-table-column>
              <el-table-column label="姓名/昵称" width="120">
                <template slot-scope="scope">
                  <span>{{scope.row.userName||scope.row.userNick}}</span>
                </template>
              </el-table-column>
              <el-table-column label="身份">
                <template slot-scope="scope">
                  <div v-if="scope.row.selfStatus==1">农业技术员</div>
                  <div v-if="scope.row.expertStatus==1">农业专家</div>
                  <div v-if="scope.row.teamStatus==1">专业农服队</div>
                  <div v-if="scope.row.marketStatus==1">农贸服务商</div>
                  <div v-if="scope.row.assetStatus==1">农资服务商</div>
                </template>
              </el-table-column>
              <el-table-column prop="userPhone" label="手机号" show-overflow-tooltip>
              </el-table-column>
            </el-table>
            <div style="text-align:right;padding:20px 0 0">
              <el-button type="primary" @click="submitForm('form')">推送</el-button>
              <el-button @click="resetForm('form')">重置</el-button>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
        
<script>
export default {
  data() {
    return {
      // 用户列表
      tableData: [],
      // 推送色值
      predefineColors: [
        '#E72D1F',
        '#0B79EE',
        '#2ACD28',
        '#F2D643',
      ],
      // 获取模板
      form: {
        id: '',
        first: '',
        keyword1: '',
        keyword2: '',
        remark: '',
        url: '',
        messageTemplateId: 'ZY-HXWwrKg8PZGeDx30IaU9XVBInkyJ6cGBgljiU7es',
        messageType: 2
      },
      rules: {
        first: [
          { required: true, message: '请输入模板副标题', trigger: 'blur' }
        ],
        keyword1: [
          { required: true, message: '请输入内容1', trigger: 'blur' }
        ],
        keyword2: [
          { required: true, message: '请输入内容2', trigger: 'blur' }
        ],
        remark: [
          { required: true, message: '请输入备注', trigger: 'blur' }
        ],
        url: [
          { required: true, message: '请输入跳转链接', trigger: 'blur' }
        ],
      },
      // 左侧标题宽度
      formLabelWidth: '120px',
      labelPosition: 'left'
    }
  },
  created() {
    this.form.id = this.$route.query.id
    this.selectJobWantedById()
    this.selectLabelFlUser()
    // this.kxiala()
  },
  methods: {
    //获取求职详情
    async selectJobWantedById() {
      const { data: res } = await this.$http.get("/admin/task/detail?taskId=" + this.form.id)
      this.form.first = '你发布的需求/任务已经有人接单了'
      this.form.keyword1 = res.data.taskTitle
      this.form.keyword2 = res.data.startTime + '至' + res.data.endTime
      this.form.remark = '点击链接了解详情，如有疑问请在对话框内咨询在线客服。'
      this.form.url = 'https://ylapp.nongliankeji.cn/pages/home/children/task/taskDetail?id=' + this.form.id
    },
    // 获取人员信息
    async selectLabelFlUser() {
      const { data: res } = await this.$http.get("/admin/message/allUserWithStatus")
      this.tableData = res.data
    },
    //全选+点选
    toggleSelection(rows) {
      if (rows.teleNum) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      var info = [];
      val.map((value, index, arry) => {
        info.push(value.userWx);
      });
      this.form.openid = info
    },
    //提交模板信息
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.pushMessage()
        }
      });
    },
    async pushMessage() {
      const { data: res } = await this.$http.post("/admin/message/pushMessage", this.form)
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      setTimeout(() => {
        this.$router.go(-1)
      }, 1000);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  }
}
</script>
<style scoped lang="less">
.el-select,
.el-cascader,
.el-date-picker {
  width: 100% !important;
}
.warps {
  background: #f5f7fa;
  padding: 20px;
}
</style>