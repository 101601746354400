<template>
  <div class='body'>
    <div class="login">
      <h2>杨凌农业人力资源共享管理平台</h2>
      <div class="login-top">
        <h1>用户登录</h1>
        <el-form class="login_form" :model="loginForm" :rules="loginFormRules" ref="loginFormRef">
          <el-form-item prop="username">
            <el-input v-model="loginForm.username" class="borderNone"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input v-model="loginForm.password" type="text" class="borderNone"></el-input>
          </el-form-item>
        </el-form>
        <div class="forgot">
          <el-button type="primary" @click="login" class="btn">登录</el-button>
        </div>
      </div>
      <div class="login-bottom">
        <h3>平台由 &nbsp;<a href="#"> 农链科技 </a>&nbsp; 提供技术支持</h3>
      </div>
    </div>
  </div>
</template>



<script>
export default {
  data() {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      loginFormRules: {
        username: [
          { required: true, message: '请输入账户名称', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
      }
    }
  },
  created() {
    let that = this;
    document.onkeypress = function (e) {
      var keycode = document.all ? event.keyCode : e.which;
      if (keycode == 13) {
        that.login();
        return false;
      }
    };
  },
  methods: {
    login() {
      this.$refs.loginFormRef.validate(async valid => {
        if (!valid) return;
        const { data: res } = await this.$http.post("/login?username=" + this.loginForm.username + '&password=' + this.loginForm.password)
        if (res.code != 200) return this.$message.error(res.message);
        this.$message.success(res.message);
        if (res.data.isOrg != 1) {
          this.$router.push('/home')
        } else {
          this.$router.push('/orgHome')
          this.$store.commit('updateData', res.data.organization)
          window.sessionStorage.setItem('organizationId', res.data.organization.organizationId)
        }
      })
    },
    reset() {
      this.$refs.loginFormRef.resetFields()
    }
  }
}
</script>



<style lang="less" scoped>
.body {
  background: url(../../assets/img/login/banner.jpg) repeat;
  font-family: "Roboto", sans-serif;
  font-size: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login {
  width: 32%;
}
.login h2 {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  margin-bottom: 50px;
  font-family: "Droid Serif", serif;
}
.login-top {
  background: #e1e1e1;
  border-radius: 25px 25px 0px 0px;
  -webkit-border-radius: 25px 25px 0px 0px;
  -moz-border-radius: 25px 25px 0px 0px;
  -o-border-radius: 25px 25px 0px 0px;
  padding: 40px 60px;
}
.login-top h1 {
  text-align: center;
  font-size: 27px;
  font-weight: 500;
  color: #1990ff;
  margin: 0px 0px 20px 0px;
}
.forgot {
  text-align: right;
}
.login-bottom {
  background: #1990ff;
  padding: 30px 65px;
  border-radius: 0px 0px 25px 25px;
  -webkit-border-radius: 0px 0px 25px 25px;
  -moz-border-radius: 0px 0px 25px 25px;
  -o-border-radius: 0px 0px 25px 25px;
  text-align: right;
  border-top: 2px solid #1990ff;
}
.login-bottom h3 {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}
.login-bottom h3 a {
  font-size: 25px;
  font-weight: 500;
  color: #fff;
}
</style>