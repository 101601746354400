<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/u_training_list' }">培训列表</el-breadcrumb-item>
            <el-breadcrumb-item>培训详情</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">培训详情</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 详情 -->
    <div class="wrap">
      <el-row :gutter="20" class="min_height_card">
        <el-col :span="24">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>培训详情</span>
              <el-button class="floatCaid" type="text" @click="toEditor(id)">编辑基本信息</el-button>
            </div>
            <div>
              <ul class="msgList">
                <li>
                  <span class="title_text">培训名称：</span>
                  <p>{{checkSelfs.trainingName}}</p>
                </li>
                <li>
                  <span class="title_text">培训类型：</span>
                  <p>{{checkSelfs.trainingType}}</p>
                </li>
                <li>
                  <span class="title_text">培训地址：</span>
                  <p>{{checkSelfs.trainingAddress}}</p>
                </li>
                <li>
                  <span class="title_text">培训环境：</span>
                  <p v-html="checkSelfs.isOnline==0?'线上':'线下'"></p>
                </li>
                <li>
                  <span class="title_text">计划人数：</span>
                  <p>{{checkSelfs.trainingPlanNumber}}</p>
                </li>
                <li>
                  <span class="title_text">培训单位：</span>
                  <p>{{checkSelfs.organizationName}}</p>
                </li>
                <li>
                  <span class="title_text">培训时间：</span>
                  <p>{{checkSelfs.trainingTime}}</p>
                </li>
                <li>
                  <span class="title_text">培训课程：</span>
                  <el-link target="_blank" @click="dialogVisible = true" type="primary" :underline="false" style="margin-bottom:20px">添加新课程</el-link>
                  <p>
                    <el-table :data="chapter" border>
                      <el-table-column prop="courseName" label="课程标题">
                      </el-table-column>
                      <el-table-column prop="teacherName" label="课程教师">
                      </el-table-column>
                      <el-table-column prop="courseStartTime" label="课程时间">
                        <template slot-scope="scope">
                          <span>{{scope.row.courseStartTime}}至{{scope.row.courseEndTime}}</span>
                        </template>
                      </el-table-column>
                      <el-table-column label="操作" width="300">
                        <template slot-scope="scope">
                          <el-button type="text" @click="dialogVisibleEditor = true;afut(scope.row)">编辑</el-button>
                          <el-popconfirm title="确定删除此条数据吗？" @confirm='removes(scope.row.courseId)'>
                            <el-button type="text" slot="reference" style="margin-left:10px">删除</el-button>
                          </el-popconfirm>
                        </template>
                      </el-table-column>
                    </el-table>
                  </p>
                </li>
                <li>
                  <span class="title_text">测评模板：</span>
                  <p>
                    <el-link target="_blank" :underline="false" type="primary" style="margin-bottom:20px" @click="downloadExcel">下载测评模板</el-link>
                  </p>
                </li>
                <li>
                  <span class="title_text">测评题上传：</span>
                  <p>
                    <el-button type="success" size="mini" @click="dialogFormVisible = true">上传评测题</el-button>
                    <span v-for="(item,i) in review" :key="i" style="display:block;margin: 10px 0 0;">{{item.evaluateIndex}}、{{item.title}}&nbsp;&nbsp;<i class="el-icon-close" @click="deleteTitle(item.evaluateIndex)"></i></span>
                  </p>
                </li>
              </ul>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <!-- 编辑章节 -->
      <el-dialog title="编辑章节" :visible.sync="dialogVisibleEditor" width="30%">
        <div style="margin-top: 15px;">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-input placeholder="请输入课程名称" v-model="editorMsg.courseName" class="input-with-select"></el-input>
            </el-col>
            <el-col :span="12">
              <el-select v-model="editorMsg.teacherId" placeholder="请选择">
                <el-option v-for="item in teacher" :key="item.value" :label="item.teacherName" :value="item.teacherId">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="24" style="display:flex;justify-content:space-between;padding-top: 20px;">
              <el-date-picker v-model="editorMsg.courseStartTime" type="datetime" placeholder="开始时间" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
              <el-date-picker v-model="editorMsg.courseEndTime" type="datetime" placeholder="结束时间" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-col>
          </el-row>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleEditor = false">关闭</el-button>
          <el-button type="primary" @click="editorTag" plain>确认修改</el-button>
        </span>
      </el-dialog>
      <!-- 添加章节 -->
      <el-dialog title="添加的章节" :visible.sync="dialogVisible" width="30%">
        <div style="margin-top: 15px;">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-input placeholder="请输入课程名称" v-model="msg.courseName" class="input-with-select"></el-input>
            </el-col>
            <el-col :span="12">
              <el-select v-model="msg.teacherId" placeholder="请选择">
                <el-option v-for="item in teacher" :key="item.value" :label="item.teacherName" :value="item.teacherId">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="24" style="display:flex;justify-content:space-between;padding-top: 20px;">
              <el-date-picker v-model="msg.courseStartTime" type="datetime" placeholder="开始时间" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
              <el-date-picker v-model="msg.courseEndTime" type="datetime" placeholder="结束时间" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-col>
          </el-row>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">关闭</el-button>
          <el-button type="primary" @click="addTag" plain>确认添加</el-button>
        </span>
      </el-dialog>
      <!-- 上传测评题 -->
      <el-dialog title="上传评测题" :visible.sync="dialogFormVisible">
        <el-form :model="form">
          <el-form-item label="测评名称" :label-width="formLabelWidth">
            <el-input v-model="form.evaluateTitle" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="测评内容" :label-width="formLabelWidth">
            <div>
              <input type="file" @change="fileChange" ref="clearFile">
            </div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="ceping">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>





<script>
export default {
  data() {
    return {
      organizationId: window.sessionStorage.getItem("organizationId"),
      id: '',
      checkSelfs: '',
      // 问题标签
      dialogVisible: false,
      dialogVisibleEditor: false,
      //课程
      chapter: [],
      //所有老师
      teacher: [],
      //编辑课程
      editorMsg: {
        courseName: '',
        teacherId: '',
        courseStartTime: '',
        courseEndTime: '',
        trainingId: ''
      },
      // 添加课程
      msg: {
        courseName: '',
        teacherId: '',
        courseStartTime: '',
        courseEndTime: '',
        trainingId: ''
      },
      // 评测提
      review: [],
      //上传评测题
      dialogFormVisible: false,
      //文件
      file: '',
      form: {
        evaluateTitle: '',
        file: '',
        trainingId: '',
      },
      FormDatas: '',
      formLabelWidth: '120px',
    }
  },
  created() {
    this.id = this.$route.query.id
    this.msg.trainingId = this.$route.query.id
    this.form.trainingId = this.$route.query.id
    this.editorMsg.trainingId = this.$route.query.id
    this.checkSelf()
    this.geteacher()
    this.getAllByTrainingId()
    this.getEvaluateTitles()
  },
  methods: {
    //点击跳转到修改信息页面
    toEditor(id) {
      this.$router.push({
        path: '/u_training_editor',
        query: {
          id: id
        }
      });
    },
    //查看
    async checkSelf() {
      const { data: res } = await this.$http.get("/admin/training/detail", {
        params: {
          trainingId: this.id
        }
      })
      this.checkSelfs = res.data
    },
    // --------------------------------------------------------------------------------------------------------------
    // 获取课程
    async getAllByTrainingId() {
      const { data: res } = await this.$http.get("/admin/course/getAllByTrainingId?trainingId=" + this.id)
      this.chapter = res.data
    },
    //删除课程
    removes(id) {
      this.deleteByPrimaryKey(id)
    },
    async deleteByPrimaryKey(id) {
      const { data: res } = await this.$http.post("/admin/course/delete", { courseId: id })
      this.getAllByTrainingId()
    },
    //获取所有老师进行关联
    async geteacher() {
      const { data: res } = await this.$http.get('/admin/teacher/getPageAllByOrganizationId', {
        params: {
          organizationId: window.sessionStorage.getItem("organizationId")
        }
      })
      this.teacher = res.data.rows
    },
    //添加课程
    addTag() {
      if (this.msg.courseName != '' && this.msg.teacherId != '' && this.msg.courseStartTime != '' && this.msg.courseEndTime != '') {
        this.insert()
      }
    },
    async insert() {
      const { data: res } = await this.$http.post("/admin/course/add", this.msg)
      this.msg.courseName = ''
      this.msg.teacherId = ''
      this.msg.courseStartTime = ''
      this.msg.courseEndTime = ''
      this.getAllByTrainingId()
      this.dialogVisible = false
    },
    //编辑课程
    afut(item) {
      this.editorMsg.courseName = item.courseName
      this.editorMsg.teacherId = item.teacherId
      this.editorMsg.courseStartTime = item.courseStartTime
      this.editorMsg.courseEndTime = item.courseEndTime
    },
    editorTag() {
      if (this.editorMsg.courseName != '' && this.editorMsg.teacherId != '' && this.editorMsg.courseStartTime != '' && this.editorMsg.courseEndTime != '') {
        this.edits()
      }
    },
    async edits() {
      const { data: res } = await this.$http.post("/admin/course/edit", this.editorMsg)
      this.editorMsg.courseName = ''
      this.editorMsg.teacherId = ''
      this.editorMsg.courseStartTime = ''
      this.editorMsg.courseEndTime = ''
      this.getAllByTrainingId()
      this.dialogVisibleEditor = false
    },
    // 下载测评题目
    downloadExcel() {
      let a = document.createElement("a");
      a.href = "/static/evaluate.xlsx";
      a.download = "evaluate.xlsx";
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
    //获取培训评测题
    async getEvaluateTitles() {
      const { data: res } = await this.$http.get("/admin/subject/getEvaluateTitles", {
        params: {
          trainingId: this.id
        }
      })
      this.review = res.data
    },
    //删除培训测评题
    deleteTitle(id) {
      this.delByTrainingIdAndEvaluateIndex(id)
    },
    async delByTrainingIdAndEvaluateIndex(id) {
      const { data: res } = await this.$http.post("/admin/subject/delByTrainingIdAndEvaluateIndex?trainingId=" + this.msg.trainingId + '&evaluateIndex=' + id)
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      this.getEvaluateTitles()
    },
    // 获取上传文件流
    fileChange(event) {
      const files = event.target.files;
      this.file = files[0]
    },
    // 确定添加培训题
    ceping() {
      var fileData = new FormData();
      fileData.append("evaluateTitle", this.form.evaluateTitle);
      fileData.append("trainingId", this.form.trainingId);
      fileData.append("file", this.file);
      this.FormDatas = fileData
      this.createWithExcel()
    },
    async createWithExcel() {
      const { data: res } = await this.$http.post("/admin/subject/createWithExcel", this.FormDatas)
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      this.dialogFormVisible = false;
      this.getEvaluateTitles()
      this.form.evaluateTitle = ''
      this.$refs.clearFile.value = '';
    },
  }
}
</script>





<style scoped lang="less">
.msgList {
  font-size: 14px;
  color: #000;
  list-style-type: none;
  li {
    position: relative;
    padding-left: 100px;
    margin-top: 20px;
    line-height: 20px;
  }
  .title_text {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 100px;
    text-align: right;
    font-weight: normal;
    color: #a4a4a4;
    padding-right: 10px;
  }
  p {
    display: inline-block;
    width: 100%;
    text-align: left;
  }
}
.title_title {
  font-size: 18px;
  color: #000;
  font-weight: bold;
  margin: 20px 0;
  text-align: center;
}
.uploadImg {
  width: 65px;
  height: 65px;
  margin-right: 10px;
}
.min_height_card {
  .box-card {
    margin-top: 20px;
    min-height: 280px;
  }
}
.floatCaid {
  float: right;
  padding: 3px 0;
}
.floatCaids {
  margin-top: -12px;
}
</style>