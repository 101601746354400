<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/label_list' }">标签列表</el-breadcrumb-item>
            <el-breadcrumb-item>编辑标签</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">编辑标签</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 详情 -->
    <div class="wrap">
      <div class="formWidth">
        <el-form :model="form" :rules="rules" ref="form" :label-position="labelPosition" :hide-required-asterisk="true">
          <div class="grid-content">
            <el-form-item label="标签类型" :label-width="formLabelWidth" prop="labelType">
              <el-select v-model="form.labelType" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="标签名称" :label-width="formLabelWidth" prop="labelName">
              <el-input v-model="form.labelName" autocomplete="off" placeholder=""></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <!-- 提交按钮 -->
      <div class="formButton">
        <el-button type="primary" @click="submitForm('form')">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //下拉框数据
      options: [{
        value: 'user',
        label: '用户标签'
      }, {
        value: 'labor',
        label: '服务标签'
      }, {
        value: 'task',
        label: '任务标签'
      }, {
        value: 'supply',
        label: '供应标签'
      }, {
        value: 'need',
        label: '采购标签'
      }],
      // 当前数据id
      id: '',
      // 表单信息
      form: {
        labelType: '',
        labelName: '',
      },
      // 表单信息验证项
      rules: {
        labelType: [
          { required: true, message: '请选择标签', trigger: 'blur' },
        ],
        labelName: [
          { required: true, message: '请输入标签名称', trigger: 'blur' },
        ],
      },
      // 左侧标题宽度
      formLabelWidth: '120px',
      //左侧标题对齐
      labelPosition: 'left'
    }
  },
  methods: {
    //提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addLabel()
        }
      });
    },
    async addLabel() {
      const { data: res } = await this.$http.post("/admin/label/addLabel", this.form)
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      setTimeout(() => {
        this.$router.go(-1)
      }, 1000);
    }
  }
}
</script>
<style scoped lang="less"></style>